@import url("https://fonts.googleapis.com/css?family=Nunito:400,500,800");
@import url("https://fonts.googleapis.com/css?family=Rubik:400,500,800");

body,
input,
button {
  font-family:
    "Rubik",
    "Helvetica Neue",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0 !important;
  padding: 0 !important;

  background-color: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);

  position: relative;
  min-height: 100vh;

  letter-spacing: 0.02em;

  user-select: none;
}

body,
html {
  overscroll-behavior: none;
}

*,
*:after,
*:before {
  box-sizing: border-box;

  transition: all linear 50ms;
  transition-property: color, background-color;
}

.grecaptcha-badge {
  visibility: hidden;
}